<template>
  <div style="width:100%;height:100%">
    <!-- 淡入 -->
    <transition name="van-fade">
      <router-view></router-view>
    </transition>
    <!-- <Tabbar></Tabbar> -->
  </div>
</template>
<script>
// import Tabbar from './tabbar';
export default {
  components: {
    // Tabbar
  },
  data() {
    return {
    }
  }
}
</script>